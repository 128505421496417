const Header = ({region,showDetails = false,totalHectares, totalFertilizers}) => {
  return (
    <div className={"flex justify-center" + (showDetails ? " mb-1" : " mb-20")}>
    <div className="text-center md:w-3/3 mx-auto">
      <h1 className="font-black text-5xl ">
        Mucuchies - <span className="text-red-700">Fertioriente</span>
      </h1>
      <h3 className="font-bold text-xl">Unidad de negocio de fertilizantes</h3>
      <h4>Detalle de los clientes atendidos</h4>
      <h4>Resumen de ventas Enero - Junio 2022</h4>
      {showDetails && <div>
      <h3 className="font-bold text-xl">Region - {region}</h3>
      <h4>Total de Fertilizantes Recibidos: {totalFertilizers}</h4>
      <h4>Hectáreas Atendidas : {totalHectares}</h4>
        </div>}
    </div>
    </div>
  );
};

export default Header;
