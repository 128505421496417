export const regions = [
  {
    id: 1,
    name: 'center',
    displayName: 'Centro',
  },
  {
    id: 2,
    name: 'western_plains',
    displayName: 'Llanos Occidentales',
  },
  {
    id: 3,
    name: 'andes_zulia',
    displayName: 'Andes Zulia',
  },
  {
    id: 4,
    name: 'west',
    displayName: 'Occidente',
  },
]

export const centerDetails = [
  // Region Centro
  {
    id: 1,
    region_id: 1,
    name: 'CAMIMPEG',
    location: 'DISTRITO CAPITAL',
    client_type: 'DISTRIBUIDOR',
    items_served: 'MAIZ',
    total_fertilizers_received: 574,
    hectares_served: 2296,
  },

  {
    id: 2,
    region_id: 1,
    name: 'AGROFANB',
    location: 'ARAGUA',
    client_type: 'USUARIO FINAL',
    items_served: 'MAIZ',
    total_fertilizers_received: 1973,
    hectares_served: 7892,
  },

  {
    id: 3,
    region_id: 1,
    name: 'LA CHINITA',
    location: 'COJEDES',
    client_type: 'PLANTA',
    items_served: 'MAIZ, ARROZ, CAÑA DE AZUCAR',
    total_fertilizers_received: 2800,
    hectares_served: 11200,
  },

  {
    id: 4,
    region_id: 1,
    name: 'FERBASA',
    location: 'ARAGUA',
    client_type: 'PLANTA',
    items_served: 'MAIZ, CAÑA DE AZUCAR, MUSACEAS, HORTALIZAS',
    total_fertilizers_received: 2031,
    hectares_served: 8124,
  },

  {
    id: 5,
    region_id: 1,
    name: 'Q-VAR',
    location: 'ARAGUA',
    client_type: 'PLANTA',
    items_served: 'MAIZ, ARROZ, CAÑA DE AZUCAR, MUSACEAS, HORTALIZAS',
    total_fertilizers_received: 60,
    hectares_served: 240,
  },

  {
    id: 6,
    region_id: 1,
    name: 'PATRIA FERTIL',
    location: 'FALCON',
    client_type: 'PLANTA',
    items_served: 'MAIZ, PASTOS, HORTALIZAS',
    total_fertilizers_received: 1047,
    hectares_served: 4188,
  },

  {
    id: 7,
    region_id: 1,
    name: 'HM SOCIAGRO',
    location: 'ARAGUA',
    client_type: 'DISTRIBUIDOR',
    items_served: 'MAIZ, CAÑA DE AZUCAR, MUSACEAS, HORTALIZAS',
    total_fertilizers_received: 460,
    hectares_served: 1840,
  },

  {
    id: 8,
    region_id: 1,
    name: 'CORP AGROFERTIL',
    location: 'CARABOBO',
    client_type: 'DISTRIBUIDOR',
    items_served: 'MAIZ, ARROZ, CAÑA DE AZUCAR, MUSACEAS, HORTALIZAS',
    total_fertilizers_received: 723,
    hectares_served: 2892,
  },

  {
    id: 9,
    region_id: 1,
    name: 'CORP H6',
    location: 'MIRANDA',
    client_type: 'DISTRIBUIDOR',
    items_served: 'HORTALIZAS, CAFÉ',
    total_fertilizers_received: 378,
    hectares_served: 1512,
  },

  {
    id: 10,
    region_id: 1,
    name: 'PROSEVENCA TURMERO',
    location: 'ARAGUA',
    client_type: 'DISTRIBUIDOR',
    items_served: 'MAIZ, CAÑA DE AZUCAR, MUSACEAS, HORTALIZAS',
    total_fertilizers_received: 269,
    hectares_served: 1076,
  },

  {
    id: 11,
    region_id: 1,
    name: 'LAS PLUMAS CAGUA',
    location: 'ARAGUA',
    client_type: 'DISTRIBUIDOR',
    items_served: 'MAIZ, CAÑA DE AZUCAR, MUSACEAS, HORTALIZAS',
    total_fertilizers_received: 358,
    hectares_served: 1432,
  },

  {
    id: 12,
    region_id: 1,
    name: 'SALDICA',
    location: 'CARABOBO',
    client_type: 'PLANTA',
    items_served: 'MAIZ',
    total_fertilizers_received: 137,
    hectares_served: 548,
  },

  {
    id: 13,
    region_id: 1,
    name: 'CORDAMI',
    location: 'MIRANDA',
    client_type: 'USUARIO FINAL',
    items_served: 'MAIZ, CAFÉ, CACAO, FLORES, MUSACEAS, HORTALIZAS',
    total_fertilizers_received: 335,
    hectares_served: 1340,
  },

  {
    id: 14,
    region_id: 1,
    name: 'SAN MARTIN DE TOURS',
    location: 'ARAGUA',
    client_type: 'USUARIO FINAL',
    items_served: 'HORTALIZAS, CAFÉ, CITRICAS',
    total_fertilizers_received: 156,
    hectares_served: 624,
  },

  {
    id: 15,
    region_id: 1,
    name: 'CAVIM',
    location: 'CARABOBO',
    client_type: 'USUARIO FINAL',
    items_served: 'MAIZ',
    total_fertilizers_received: 150,
    hectares_served: 600,
  },

  {
    id: 16,
    region_id: 1,
    name: 'AGROSERVICIOS ELSAUZ',
    location: 'CARABOBO',
    client_type: 'DISTRIBUIDOR',
    items_served: 'MAIZ, CAÑA DE AZUCAR, MUSACEAS, HORTALIZAS, CITRICAS',
    total_fertilizers_received: 229,
    hectares_served: 916,
  },

  {
    id: 17,
    region_id: 1,
    name: 'MERSAN',
    location: 'CARABOBO',
    client_type: 'DISTRIBUIDOR',
    items_served: 'MAIZ, CAÑA DE AZUCAR, MUSACEAS, HORTALIZAS',
    total_fertilizers_received: 0,
    hectares_served: 0,
  }
]

export const westernPlainsDetails = [

  { id : 1 , region_id: 2, name: "ASOPORTUGUESA", location: "PORTUGUESA",  client_type: "ASOC. PRODUCTORES", items_serverd: "MAIZ-ARROZ", total_fertilizers_received: 6383, hectares_served: 21280 },
{ id : 2 , region_id: 2, name: "APRADOC", location: "PORTUGUESA",  client_type: "ASOC. PRODUCTORES", items_serverd: "MAIZ", total_fertilizers_received: 3083, hectares_served: 10280 },
{ id : 3 , region_id: 2, name: "ASOGIPA", location: "PORTUGUESA",  client_type: "ASOC. PRODUCTORES", items_serverd: "MAIZ ARROZ", total_fertilizers_received: 4342, hectares_served: 14480 },
{ id : 4 , region_id: 2, name: "FERREAGRO ACARIGUA", location: "PORTUGUESA",  client_type: "DISTRIBUIDOR", items_serverd: "MAIZ-ARROZ", total_fertilizers_received: 2362, hectares_served: 7880 },
{ id : 5 , region_id: 2, name: "IANCA", location: "PORTUGUESA",  client_type: "ASOC. PRODUCTORES", items_serverd: "MAIZ-ARROZ", total_fertilizers_received: 2306, hectares_served: 7690 },
{ id : 6 , region_id: 2, name: "CAMPO SANO", location: "PORTUGUESA",  client_type: "ASOC. PRODUCTORES", items_serverd: "MAIZ", total_fertilizers_received: 1281, hectares_served: 4270 },
{ id : 7 , region_id: 2, name: "AGROINPROA", location: "PORTUGUESA",  client_type: "ASOC. PRODUCTORES", items_serverd: "MAIZ-ARROZ", total_fertilizers_received: 1982, hectares_served: 6610 },
{ id : 8 , region_id: 2, name: "VV AGROINJSUMOS", location: "PORTUGUESA",  client_type: "DISTRIBUIDOR", items_serverd: "MAIZ-ARROZ-CAÑA-CAFÉ", total_fertilizers_received: 2810, hectares_served: 9370 },
{ id : 9 , region_id: 2, name: "EL RETORNO", location: "PORTUGUESA",  client_type: "USUARIO FINAL", items_serverd: "CAÑA", total_fertilizers_received: 851, hectares_served: 2840 },
{ id : 10 , region_id: 2, name: "VENAGRIN", location: "PORTUGUESA",  client_type: "ASOC. PRODUCTORES", items_serverd: "ARROZ", total_fertilizers_received: 907, hectares_served: 3030 },
{ id : 11 , region_id: 2, name: "MARDUK8", location: "PORTUGUESA",  client_type: "DISTRIBUIDOR", items_serverd: "MAIZ-ARROZ", total_fertilizers_received: 992, hectares_served: 3310 },
{ id : 12 , region_id: 2, name: "TOPAGRO GUANARE", location: "PORTUGUESA",  client_type: "DISTRIBUIDOR", items_serverd: "CAÑA-MAIZ", total_fertilizers_received: 400, hectares_served: 1340 },
{ id : 13 , region_id: 2, name: "TOPAGRO GUANARITO", location: "PORTUGUESA",  client_type: "DISTRIBUIDOR", items_serverd: "CAÑA-MAIZ-MELON-PATILLA", total_fertilizers_received: 213, hectares_served: 710 },
{ id : 14 , region_id: 2, name: "ALFA & OMEGA", location: "PORTUGUESA",  client_type: "DISTRIBUIDOR", items_serverd: "MAIZ-ARROZ", total_fertilizers_received: 967, hectares_served: 3230 },
{ id : 15 , region_id: 2, name: "FUTURAGRO BARINAS", location: "BARINAS",  client_type: "ASOC. PRODUCTORES", items_serverd: "MAIZ-ARROZ", total_fertilizers_received: 1098, hectares_served: 3660 },
{ id : 16 , region_id: 2, name: "COMERCIAL ITALVEN", location: "PORTUGUESA",  client_type: "DISTRIBUIDOR", items_serverd: "MAIZ-CAÑA", total_fertilizers_received: 1000, hectares_served: 3000 },
{ id : 17 , region_id: 2, name: "COMERCIAL ITALVEN", location: "BARINAS",  client_type: "DISTRIBUIDOR", items_serverd: "MAIZ", total_fertilizers_received: 378, hectares_served: 1260 },
{ id : 18 , region_id: 2, name: "LAS PLUMAS", location: "PORTUGUESA",  client_type: "DISTRIBUIDOR", items_serverd: "MAIZ-ARROZ-CAÑA", total_fertilizers_received: 359, hectares_served: 1200 },
{ id : 19 , region_id: 2, name: "LA PLUMAS ", location: "BARINAS",  client_type: "DISTRIBUIDOR", items_serverd: "MAIZ-ARROZ-CAÑA", total_fertilizers_received: 90, hectares_served: 300 },
{ id : 20 , region_id: 2, name: "AGROQ Y FERT DEL SUR", location: "COJEDES",  client_type: "DISTRIBUIDOR", items_serverd: "CAÑA-CAFÉ.ARROZ-MAIZ", total_fertilizers_received: 1970, hectares_served: 6570 },
{ id : 21 , region_id: 2, name: "AGROTUREN", location: "PORTUGUESA",  client_type: "ASOC. PRODUCTORES", items_serverd: "MAIZ", total_fertilizers_received: 627, hectares_served: 2090 },
{ id : 22 , region_id: 2, name: "CAAEZ", location: "BARINAS",  client_type: "DISTRIBUIDOR", items_serverd: "MAIZ-ARROZ-CAÑA-PLATANO", total_fertilizers_received: 388, hectares_served: 1300 },
{ id : 23 , region_id: 2, name: "ANCA", location: "PORTUGUESA",  client_type: "ASOC. PRODUCTORES", items_serverd: "MAIZ", total_fertilizers_received: 900, hectares_served: 3000 },
{ id : 24 , region_id: 2, name: "ANCA BARINAS", location: "BARINAS",  client_type: "ASOC. PRODUCTORES", items_serverd: "MAIZ", total_fertilizers_received: 265, hectares_served: 885 },
{ id : 25 , region_id: 2, name: "AGROSOL", location: "PORTUGUESA",  client_type: "ASOC. PRODUCTORES", items_serverd: "ARROZ", total_fertilizers_received: 286, hectares_served: 960 },
{ id : 26 , region_id: 2, name: "PACCA SUCRE", location: "PORTUGUESA",  client_type: "DISTRIBUIDOR", items_serverd: "CAFÉ", total_fertilizers_received: 234, hectares_served: 780 },
{ id : 27 , region_id: 2, name: "VEN AL AGRO", location: "BARINAS",  client_type: "PLANTA MEZCLADORA", items_serverd: "MAIZ-ARROZ-PLATANO-TOMATE", total_fertilizers_received: 300, hectares_served: 1000 },
{ id : 28 , region_id: 2, name: "SOCASERVICIOS", location: "PORTUGUESA",  client_type: "ASOC. PRODUCTORES", items_serverd: "CAÑA", total_fertilizers_received: 138, hectares_served: 460 },
{ id : 29 , region_id: 2, name: "PROSENVENCA ACARIGUA", location: "PORTUGUESA",  client_type: "DISTRIBUIDOR", items_serverd: "MAIZ-ARROZ", total_fertilizers_received: 120, hectares_served: 400 },
{ id : 30 , region_id: 2, name: "INPROFEC", location: "PORTUGUESA",  client_type: "PROGRAMA ESTADO", items_serverd: "MAIZ-CAÑA-ARROZ-CAFÉ", total_fertilizers_received: 460, hectares_served: 1540 },
{ id : 31 , region_id: 2, name: "ASOCAPILLA", location: "PORTUGUESA",  client_type: "USUARIO FINAL", items_serverd: "ARROZ", total_fertilizers_received: 385, hectares_served: 1290 },
{ id : 32 , region_id: 2, name: "FINARMA", location: "PORTUGUESA",  client_type: "ASOC. PRODUCTORES", items_serverd: "ARROZ", total_fertilizers_received: 150, hectares_served: 500 },
{ id : 33 , region_id: 2, name: "PACCA CHABASQUEN", location: "PORTUGUESA",  client_type: "DISTRIBUIDOR", items_serverd: "CAFÉ", total_fertilizers_received: 90, hectares_served: 300 },
{ id : 34 , region_id: 2, name: "PACCA OSPINO", location: "PORTUGUESA",  client_type: "DISTRIBUIDOR", items_serverd: "CAFÉ", total_fertilizers_received: 107, hectares_served: 360 },
{ id : 35 , region_id: 2, name: "IN HOUSE", location: "PORTUGUESA",  client_type: "DISTRIBUIDOR", items_serverd: "MAIZ-ARROZ", total_fertilizers_received: 60, hectares_served: 200 },
{ id : 36 , region_id: 2, name: "SERVIAR", location: "PORTUGUESA",  client_type: "DISTRIBUIDOR", items_serverd: "MAIZ-ARROZ", total_fertilizers_received: 30, hectares_served: 100 },
{ id : 37 , region_id: 2, name: "PAI", location: "PORTUGUESA",  client_type: "ASOC. PRODUCTORES", items_serverd: "MAIZ", total_fertilizers_received: 103, hectares_served: 350 },
{ id : 38 , region_id: 2, name: "VEALCA", location: "PORTUGUESA",  client_type: "ASOC. PRODUCTORES", items_serverd: "ARROZ", total_fertilizers_received: 159, hectares_served: 530 },
{ id : 39 , region_id: 2, name: "AFAVEN", location: "PORTUGUESA",  client_type: "ASOC. PRODUCTORES", items_serverd: "MAIZ-ARROZ", total_fertilizers_received: 607, hectares_served: 2030 },
{ id : 40 , region_id: 2, name: "LA VAQUERA", location: "PORTUGUESA",  client_type: "ASOC. PRODUCTORES", items_serverd: "MAIZ", total_fertilizers_received: 200, hectares_served: 670 },
{ id : 41 , region_id: 2, name: "AGROSILCA", location: "PORTUGUESA",  client_type: "ASOC. PRODUCTORES", items_serverd: "ARROZ", total_fertilizers_received: 40, hectares_served: 140 },
{ id : 42 , region_id: 2, name: "PRO INSUMOS MAXIMA", location: "PORTUGUESA",  client_type: "USUARIO FINAL", items_serverd: "MAIZ", total_fertilizers_received: 2819, hectares_served: 9400 },
{ id : 43 , region_id: 2, name: "ASOPRUAT", location: "PORTUGUESA",  client_type: "ASOC. PRODUCTORES", items_serverd: "MAIZ", total_fertilizers_received: 997, hectares_served: 3340 },
{ id : 44 , region_id: 2, name: "EPS DOÑA ZAIDA", location: "PORTUGUESA",  client_type: "DISTRIBUIDOR", items_serverd: "CAFÉ", total_fertilizers_received: 30, hectares_served: 100 },
{ id : 45 , region_id: 2, name: "CAFÉ SUBLIME", location: "PORTUGUESA",  client_type: "DISTRIBUIDOR", items_serverd: "CAFÉ", total_fertilizers_received: 30, hectares_served: 100 },
{ id : 46 , region_id: 2, name: "ASOC. AGROTIMOR", location: "PORTUGUESA",  client_type: "DISTRIBUIDOR", items_serverd: "CAFÉ", total_fertilizers_received: 30, hectares_served: 100 },
{ id : 47 , region_id: 2, name: "CAFÉ SOL DEL LLANO", location: "PORTUGUESA",  client_type: "DISTRIBUIDOR", items_serverd: "CAFÉ", total_fertilizers_received: 30, hectares_served: 100 },
{ id : 48 , region_id: 2, name: "SALVADOR RUSSO", location: "PORTUGUESA",  client_type: "USUARIO FINAL", items_serverd: "MAIZ", total_fertilizers_received: 69, hectares_served: 230 },
{ id : 49 , region_id: 2, name: "CLEMENTE LOPEZ", location: "PORTUGUESA",  client_type: "USUARIO FINAL", items_serverd: "CAÑA-ARROZ", total_fertilizers_received: 30, hectares_served: 100 },
{ id : 50 , region_id: 2, name: "MAXIMO TORRES", location: "PORTUGUESA",  client_type: "USUARIO FINAL", items_serverd: "CAÑA", total_fertilizers_received: 20, hectares_served: 70 }
]


export const andesZuliaDetails = [

  { id : 1 , region_id: 3, name: "AGROFERRETERIA MAVERABA C.A", location: "MERIDA",  client_type: "PLANTA MEZCLADORA", items_serverd: "PALMA, CAFÉ, PAPA, PLATANO, MAIZ, CEBOLLA, TOMATE, CAÑA DE AZUCAR", total_fertilizers_received: 350, hectares_served: 455 },
{ id : 2 , region_id: 3, name: "AGROBOLMERCA", location: "MERIDA",  client_type: "DISTRIBUIDOR", items_serverd: "CAÑA DE AZUCAR, ZANAHORIA, CARAOTA, MAIZ, CALABACIN, TOMATE, CAFÉ, PLATANO, PARCHITA, NARANJA, CAMBUR, PIMENTON, PAPA,CACAO", total_fertilizers_received: 120, hectares_served: 280 },
{ id : 3 , region_id: 3, name: "APROPATACHIRA C.A", location: "TACHIRA",  client_type: "DISTRIBUIDOR", items_serverd: "", total_fertilizers_received: 60, hectares_served: 140 },
{ id : 4 , region_id: 3, name: "COOPAC RL", location: "TACHIRA",  client_type: "DISTRIBUIDOR", items_serverd: "HORTALIZAS,VERDURAS,LEGUMBRES Y TUBERCULOS ", total_fertilizers_received: 117, hectares_served: 669 },
{ id : 5 , region_id: 3, name: "FERSULCA", location: "MERIDA",  client_type: "PLANTA MEZCLADORA", items_serverd: "PLATANO, PALMA Y CAFÉ", total_fertilizers_received: 1392.77, hectares_served: 3481 },
{ id : 6 , region_id: 3, name: "COMERCIALIZADORA EL ROBLE DE LOS ANDES, C.A.", location: "TACHIRA",  client_type: "DISTRIBUIDOR", items_serverd: "CACAO, PLATANO, PIÑA, LECHOZA, PASTO, MAIZ, PIÑA, PARCHITA, OCUMO, AJI DULCE, PIMENTON, YUCA, LIMON, GUINEO, CAÑA", total_fertilizers_received: 239.5, hectares_served: 1230 },
{ id : 7 , region_id: 3, name: "AGROINVERSIONES.C.A.", location: "MERIDA",  client_type: "PLANTA MEZCLADORA", items_serverd: "PLATANO, PALMA, PASTO, LECHOZA, CITRICOS, HORTALIZ", total_fertilizers_received: 1326, hectares_served: 3450 },
{ id : 8 , region_id: 3, name: "AGROPECUARIA RAMFOR C.A ", location: "MERIDA",  client_type: "DISTRIBUIDOR ", items_serverd: "PLATANO/ HORTALIZAS/ PASTOS/PALMA/ CACAO/ CAFÉ/CITRICOS", total_fertilizers_received: 239.42, hectares_served: 546.5 },
{ id : 9 , region_id: 3, name: "AGROPECUARIA DEL SUR,C.A", location: "MERIDA",  client_type: "DISTRIBUIDOR ", items_serverd: "PLATANO/ HORTALIZAS/ PASTOS/PALMA/ CACAO/ CAFÉ/CITRICOS", total_fertilizers_received: 179, hectares_served: 836 },
{ id : 10 , region_id: 3, name: "INDUSTRIAS AGROALIMENTARIAS VICCON C.A", location: "TACHIRA",  client_type: "PLANTA MEZCLADORA", items_serverd: "MAIZ, CAÑA, CAFÉ, HORTALIZAS, SOJA, ARROZ", total_fertilizers_received: 380.2, hectares_served: 1.266 },
{ id : 11 , region_id: 3, name: "AGROPECUARIA DEL NORTE C.A. ", location: "MERIDA",  client_type: "DISTRIBUIDOR", items_serverd: "PLÁTANO, LECHOSA PALMA, HORTALIZAS, PASTO ", total_fertilizers_received: 240, hectares_served: 2100 },
{ id : 12 , region_id: 3, name: "DISTRIBUIDORA AGROINSUMOS EL CAMPO C.A.", location: "TRUJILLO",  client_type: "DISTRIBUIDOR", items_serverd: "CAFÉ, MAIZ, CARAOTA, PAPA, TOMATE, PIMENTON, BROCOLI, COLIFLOR, REPOLLO, PEPINO, REMOLACHA", total_fertilizers_received: 390, hectares_served: 1300 },
{ id : 13 , region_id: 3, name: "COOPERATIVA SOBRE LA ROCA", location: "TRUJILLO",  client_type: "DISTRIBUIDOR", items_serverd: "MAIZ, CAÑA DE AZUCAR, PAPA, ZANAHORIA, TOMATE", total_fertilizers_received: 150, hectares_served: 450 },
{ id : 14 , region_id: 3, name: "AGROANDES", location: "TRUJILLO",  client_type: "DISTRIBUIDOR", items_serverd: "PAPA, MAIZ, CAFÉ, PIMENTON, TOMATE, REPOLLO", total_fertilizers_received: 120, hectares_served: 564 },
{ id : 15 , region_id: 3, name: "AGROPANAMERICANA", location: "TRUJILLO",  client_type: "DISTRIBUIDOR", items_serverd: "LECHOSA, PLATANO, CAMBUR, CAÑA DE AZUCAR, YUCA", total_fertilizers_received: 377, hectares_served: 643 },
{ id : 16 , region_id: 3, name: "DIAGROBAICA", location: "MERIDA",  client_type: "DISTRIBUIDOR", items_serverd: "PAPA, ZANAHORIA, HORTALIZAS, PASTO, CAFÉ", total_fertilizers_received: 180, hectares_served: 540 },
{ id : 17 , region_id: 3, name: "ASOCIACIÓN EL TRIANGULO DE MOCOTIES", location: "MERIDA",  client_type: "USUARIO FINAL", items_serverd: "CAFÉ", total_fertilizers_received: 30, hectares_served: 110 },
{ id : 18 , region_id: 3, name: "ASOCIACIÓN DE PRODUCTORES FRONTINOS", location: "TRUJILLO",  client_type: "USUARIO FINAL", items_serverd: "CAFÉ", total_fertilizers_received: 30, hectares_served: 125 },
{ id : 19 , region_id: 3, name: "ASOCIACIÓN DE PRODUCTORES EL TRIANGULO CAFETALERO", location: "TRUJILLO",  client_type: "USUARIO FINAL", items_serverd: "CAFÉ", total_fertilizers_received: 30, hectares_served: 95 },

]

export const westDetails = [
  { id: 1, region_id: 4, name: "Agro Lara", location: "LARA", client_type: "DISTRIBUIDOR", items_serverd: "MAIZ, CAÑA DE AZUCAR, HORTALIZAS, CAFÉ, PIÑA Y FRUTALES", total_fertilizers_received: 234.44, hectares_served: 1563 },
  { id: 2, region_id: 4, name: "Agroquim", location: "YARACUY", client_type: "PLANTA MEZCLADORA", items_serverd: "CEREALES, CAÑA DE AZUCAR, CITRICOS, TOMATE, AGUACATE, PIMENTON, PLATANO, HORTALIZAS Y CAFÉ", total_fertilizers_received: 2825.93, hectares_served: 14130 },
  { id: 3, region_id: 4, name: "Café Tradición de Oro", location: "LARA", client_type: "DISTRIBUIDOR", items_serverd: "CAFÉ Y HORTALIZAS", total_fertilizers_received: 21, hectares_served: 105 },
  { id: 4, region_id: 4, name: "Cathequim", location: "YARACUY", client_type: "PLANTA MEZCLADORA", items_serverd: "CEREALES Y HORTALIZAS", total_fertilizers_received: 320.47, hectares_served: 1602 },
  { id: 5, region_id: 4, name: "Distribuidora Las Hermanas", location: "LARA", client_type: "DISTRIBUIDOR", items_serverd: "CAFÉ, HORTALIZAS Y CARAOTAS", total_fertilizers_received: 209.75, hectares_served: 1260 },
  { id: 6, region_id: 4, name: "Ferturca", location: "LARA", client_type: "PLANTA MEZCLADORA", items_serverd: "MUSACEAS, CAÑA DE AZUCAR, CEREALES Y HORTALIZAS", total_fertilizers_received: 3567.55, hectares_served: 8562 },
  { id: 7, region_id: 4, name: "Germadi", location: "YARACUY", client_type: "DISTRIBUIDOR", items_serverd: "CARAOTAS, CEREALES, CITRICOS, CAÑA DE AZUCAR, PLATANO, AGUACATE, TOMATE Y PASTOS", total_fertilizers_received: 179, hectares_served: 895 },
  { id: 8, region_id: 4, name: "Innova Business", location: "LARA", client_type: "PLANTA MEZCLADORA", items_serverd: "CEREALES, HORTALIZAS, CARAOTAS, CAFÉ Y CAÑA DE AZUCAR", total_fertilizers_received: 14436.15, hectares_served: 48786 },
  { id: 9, region_id: 4, name: "Pacca Guarico", location: "LARA", client_type: "DISTRIBUIDOR", items_serverd: "HORTALIZAS Y CAFÉ", total_fertilizers_received: 90, hectares_served: 450 },
  { id: 10, region_id: 4, name: "Pacca Sanare", location: "LARA", client_type: "DISTRIBUIDOR", items_serverd: "HORTALIZAS Y CAFÉ", total_fertilizers_received: 59.1, hectares_served: 359 },
  { id: 11, region_id: 4, name: "Serviagro Tocuyo", location: "LARA", client_type: "DISTRIBUIDOR", items_serverd: "CEREALES, HORTALIZA, CAFÉ Y PASTOS", total_fertilizers_received: 314, hectares_served: 1570 },
  { id: 12, region_id: 4, name: "SocaTorres", location: "LARA", client_type: "DISTRIBUIDOR", items_serverd: "CEREALES, HORTALIZAS, CAÑA DE AZUCAR,  CEBOLLA Y TOMATE.", total_fertilizers_received: 180, hectares_served: 900 },
  { id: 13, region_id: 4, name: "El Tunal", location: "LARA", client_type: "UNIDAD DE PRODUCCION", items_serverd: "MAIZ Y PASTOS", total_fertilizers_received: 2332.06, hectares_served: 9328 },
  { id: 14, region_id: 4, name: "Casagricola", location: "LARA", client_type: "DISTRIBUIDOR", items_serverd: "CEREALES, HORTALIZA, CAFÉ, TOMATE, AGUACATE, MELON, CARAOTA Y AJI", total_fertilizers_received: 665.85, hectares_served: 3218 },
  { id: 15, region_id: 4, name: "Esporadicos", location: "LARA", client_type: "USUARIO FINAL", items_serverd: "CAFÉ, HORTALIZAS, CEREALES Y CARAOTAS", total_fertilizers_received: 199, hectares_served: 757 },

]

export const centerTotal = {
  region_name : "Centro",
  total_fertilizers_received : 11680,
  hectares_served : 46720

}
export const westernPlainsTotal = {
  region_name : "Llanos Occidentales",
  total_fertilizers_received : 43478,
  hectares_served : 144735

}

export const andesZuliaTotal = {
  region_name : "Andes Zulia",
  total_fertilizers_received : 5570.69,
  hectares_served : 17016

}

export const westTotal = {
  region_name : "Occidente",
  total_fertilizers_received : 25634,
  hectares_served : 93485

}

