import {westDetails, centerDetails, westernPlainsDetails, andesZuliaDetails,westTotal, centerTotal, westernPlainsTotal, andesZuliaTotal} from '../mocks'
const  arrayRegionDetails = {
    1 : {
        data : centerDetails
    },
    2 : {
        data: westernPlainsDetails
    },
    3 : {
        data : andesZuliaDetails
    },
    4 : {
        data : westDetails
    }
}
const  arrayRegionTotals = {
    centro : {
        data : centerTotal
    },
    llanos_occidentales : {
        data: westernPlainsTotal
    },
    andes_zulia : {
        data : andesZuliaTotal
    },
    occidente : {
        data : westTotal
    }
}

export const fetchdataDetails = (region) => {
    return new Promise((resolve, reject) => {
        if(region in arrayRegionDetails){
            resolve(arrayRegionDetails[region])
        }
        else{
            reject([])

        }
    })

}

export const fetchdataTotal = (region) => {
    return new Promise((resolve, reject) => {
        if(region in arrayRegionTotals){
            resolve(arrayRegionTotals[region])
        }
        
            reject([])

        
    })

}
