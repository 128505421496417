import React, { useRef, useEffect, useState } from "react"
import { ReactComponent as ReactLogo } from './VE-ES.svg';


export default function MapChart({ selectRegion, selecRegionName }) {

  const ref = useRef(null);
  const [map, setMap] = useState(null)

  useEffect(() => {
    setMap(ref.current)

  }, []);

  useEffect(() => {
    if (map) {
      const handleClick = (e) => {
        if (e.target.nodeName == "path") {
          let region = e.target.getAttribute('region_id') ?? ''
          selectRegion(region)
        }
      }
      const mouseEntered = (e) => {
        var target = e.target;
        if (target.nodeName == "path") {
          let region = target.getAttribute('region') ?? ''
          if (region.length > 0) {
            selecRegionName(region)
          }
          Array.prototype.slice.call(map.children).filter(item => item.getAttribute('region') === region).forEach(element => {
            element.style.opacity = 0.6
            element.style.cursor = "pointer"
          });



        }

      }

      const mouseGone = (e) => {
        var target = e.target;
        if (target.nodeName == "path") {
          target.style.opacity = 1;
          target.style.cursor = "default"
          let region = target.getAttribute('region') ?? ''
          selecRegionName('')
          Array.prototype.slice.call(map.children).filter(item => item.getAttribute('region') === region).forEach(element => {
            element.style.opacity = 1
          });
        }
      }

      map.addEventListener("click", handleClick, false);
      map.addEventListener("mousemove", mouseEntered, false);
      map.addEventListener("mouseout", mouseGone, false);
      return () => {
        map.removeEventListener("click", handleClick);
        map.removeEventListener('mousemove', mouseEntered);
        map.removeEventListener('mouseout', mouseGone);
      };
    }

  }, [map])



  return (
    <div class="h-100 grid grid-cols-1 gap-4 content-center">
      <div>
        <ReactLogo ref={ref}></ReactLogo>

      </div>
    </div>

  )
}