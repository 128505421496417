import {Header, Map, Table,SelectColumnFilter, CellInt} from "./app/components";
import React, {useEffect, useState} from "react";
import { fetchdataDetails,fetchdataTotal } from "./app/api";


const App = () => {
  const [showDetails, setShowDetails] = useState(false)
  const [nameRegion, setNameRegion] = useState('')
  const [regionNameSelected, setRegionNameSelected] = useState('')
  const [totalHectares, setTotalHectares] = useState(0)
  const [totalFertilizers, setTotalFertilizers] = useState(0)
  const [regionIDSelected, setRegionIDSelected] = useState(null);
  const [dataRegion, setDataRegion] = useState([])
  const [showMap, setShowMap] = useState(true)
  const columns = React.useMemo(() => [
    {
      Header: "Nombre",
      accessor: 'name',
    },
    {
      Header: "Ubicacion(Estado)",
      accessor: 'location',
      Filter: SelectColumnFilter,  // new
      filter: 'includes',
    },
    {
      Header: "Tipo de Cliente",
      accessor: 'client_type',

    },
    {
      Header: "Rubros Atendidos",
      accessor: 'items_served',
    },
    {
      Header: "Total Fertilizantes recibidos",
      accessor: 'total_fertilizers_received',
      Cell : CellInt

    },
    {
      Header: "Hectáreas atendidas con los fertilizantes ",
      accessor: 'hectares_served',
      Cell : CellInt

    },
  ], [])
  useEffect(() => {

    if(regionIDSelected){
      fetchdataDetails(regionIDSelected).then((response) => {
       setShowMap(false)
       setDataRegion([...response.data])
      }).catch(() => {
        alert('Region no Disponible')
      })
    }
  }, [regionIDSelected])

  useEffect(() => {

    if(regionNameSelected.length > 0){
      fetchdataTotal(regionNameSelected).then((response) => {
        setTotalFertilizers(parseFloat(response.data.total_fertilizers_received).toLocaleString('es-VE'))
        setTotalHectares(parseFloat(response.data.hectares_served).toLocaleString('es-VE'))
        setNameRegion(response.data.region_name)
        setShowDetails(true)
       }).catch(() => {
         alert('Region no Disponible')
       })
    }
    else {
      setShowDetails(false)
    }
  }, [regionNameSelected])

  useEffect(() => {
    if(showMap){
      setRegionIDSelected(null)
      setShowDetails(false)
    }

  }, [showMap])
  
  return (
    <div className="container mx-auto mt-20">
      <Header  region={nameRegion} showDetails={showDetails} totalFertilizers={totalFertilizers} totalHectares={totalHectares}/>
      {showMap && <Map  selectRegion={setRegionIDSelected} selecRegionName={setRegionNameSelected} showDetails={setShowDetails}/>}
      {!showMap &&  <Table columns={columns} data={dataRegion} showMap={setShowMap} />}
      
    </div>
  )
}

export default App;
